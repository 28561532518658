import { Node, mergeAttributes, Mark, getMarkAttributes, wrappingInputRule } from '@tiptap/core';

/**
 * This extension allows you to create list items.
 * @see https://www.tiptap.dev/api/nodes/list-item
 */
const ListItem = Node.create({
  name: 'listItem',
  addOptions() {
    return {
      HTMLAttributes: {},
      bulletListTypeName: 'bulletList',
      orderedListTypeName: 'orderedList'
    };
  },
  content: 'paragraph block*',
  defining: true,
  parseHTML() {
    return [{
      tag: 'li'
    }];
  },
  renderHTML({
    HTMLAttributes
  }) {
    return ['li', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
  addKeyboardShortcuts() {
    return {
      Enter: () => this.editor.commands.splitListItem(this.name),
      Tab: () => this.editor.commands.sinkListItem(this.name),
      'Shift-Tab': () => this.editor.commands.liftListItem(this.name)
    };
  }
});

/**
 * This extension allows you to create text styles. It is required by default
 * for the `textColor` and `backgroundColor` extensions.
 * @see https://www.tiptap.dev/api/marks/text-style
 */
const TextStyle = Mark.create({
  name: 'textStyle',
  priority: 101,
  addOptions() {
    return {
      HTMLAttributes: {}
    };
  },
  parseHTML() {
    return [{
      tag: 'span',
      getAttrs: element => {
        const hasStyles = element.hasAttribute('style');
        if (!hasStyles) {
          return false;
        }
        return {};
      }
    }];
  },
  renderHTML({
    HTMLAttributes
  }) {
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
  addCommands() {
    return {
      removeEmptyTextStyle: () => ({
        state,
        commands
      }) => {
        const attributes = getMarkAttributes(state, this.type);
        const hasStyles = Object.entries(attributes).some(([, value]) => !!value);
        if (hasStyles) {
          return true;
        }
        return commands.unsetMark(this.name);
      }
    };
  }
});

/**
 * Matches an ordered list to a 1. on input (or any number followed by a dot).
 */
const inputRegex = /^(\d+)\.\s$/;
/**
 * This extension allows you to create ordered lists.
 * This requires the ListItem extension
 * @see https://www.tiptap.dev/api/nodes/ordered-list
 * @see https://www.tiptap.dev/api/nodes/list-item
 */
const OrderedList = Node.create({
  name: 'orderedList',
  addOptions() {
    return {
      itemTypeName: 'listItem',
      HTMLAttributes: {},
      keepMarks: false,
      keepAttributes: false
    };
  },
  group: 'block list',
  content() {
    return `${this.options.itemTypeName}+`;
  },
  addAttributes() {
    return {
      start: {
        default: 1,
        parseHTML: element => {
          return element.hasAttribute('start') ? parseInt(element.getAttribute('start') || '', 10) : 1;
        }
      },
      type: {
        default: null,
        parseHTML: element => element.getAttribute('type')
      }
    };
  },
  parseHTML() {
    return [{
      tag: 'ol'
    }];
  },
  renderHTML({
    HTMLAttributes
  }) {
    const {
      start,
      ...attributesWithoutStart
    } = HTMLAttributes;
    return start === 1 ? ['ol', mergeAttributes(this.options.HTMLAttributes, attributesWithoutStart), 0] : ['ol', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
  addCommands() {
    return {
      toggleOrderedList: () => ({
        commands,
        chain
      }) => {
        if (this.options.keepAttributes) {
          return chain().toggleList(this.name, this.options.itemTypeName, this.options.keepMarks).updateAttributes(ListItem.name, this.editor.getAttributes(TextStyle.name)).run();
        }
        return commands.toggleList(this.name, this.options.itemTypeName, this.options.keepMarks);
      }
    };
  },
  addKeyboardShortcuts() {
    return {
      'Mod-Shift-7': () => this.editor.commands.toggleOrderedList()
    };
  },
  addInputRules() {
    let inputRule = wrappingInputRule({
      find: inputRegex,
      type: this.type,
      getAttributes: match => ({
        start: +match[1]
      }),
      joinPredicate: (match, node) => node.childCount + node.attrs.start === +match[1]
    });
    if (this.options.keepMarks || this.options.keepAttributes) {
      inputRule = wrappingInputRule({
        find: inputRegex,
        type: this.type,
        keepMarks: this.options.keepMarks,
        keepAttributes: this.options.keepAttributes,
        getAttributes: match => ({
          start: +match[1],
          ...this.editor.getAttributes(TextStyle.name)
        }),
        joinPredicate: (match, node) => node.childCount + node.attrs.start === +match[1],
        editor: this.editor
      });
    }
    return [inputRule];
  }
});
export { OrderedList, OrderedList as default, inputRegex };
